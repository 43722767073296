<template>
  <div class='regist-guide'>
  </div>
</template>

<script>
  export default {
    name: 'registGuide',
    components: {},
    data() {
      return {}
    },
    computed: {},
    watch: {},
    mounted() {},
    methods: {
      handleNext() {
        this.$router.back(-1);
      }
    }
  };
</script>

<style lang='less' scoped>
</style>